import { Swiper, SwiperOptions } from 'swiper';
import SwiperCore, {
  Navigation,
  Lazy,
} from 'swiper';
import Alpine from 'alpinejs'

// install Swiper modules
SwiperCore.use([Navigation, Lazy]);

import Component from "../../../../base/Component";

export default class GalleryModal extends Component {

    sliderInstance: Swiper|null = null;


    init() {
        const store = Alpine.store('gallery', {
            modalVisible: false,
            projectId: null,
            launchModal(projectId: number) {
                this.modalVisible = true;
                this.projectId = projectId;
            },
            projectIdUpdated: (projectId: number) => {
                htmx.trigger(htmx.find('#gallery-modal'), 'refresh', {projectId: projectId});
            },
            visibilityUpdated: (visible: boolean) => {
                if (visible) {
                    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
                } else {
                    document.getElementsByTagName('html')[0].style.overflow = 'initial';
                }
            }
        })

        const swiper: SwiperOptions = {
            slidesPerView: 1,
            lazy: true,
            loop: true,
            preloadImages: false,
            navigation: {
                prevEl: '.slider-gallery-modal__control-prev',
                nextEl: '.slider-gallery-modal__control-next',
            },
            cssMode: true,
        };

        htmx.on('htmx:afterSettle', (event) => {
            if(event.target.id === 'gallery-modal') {
                this.sliderInstance = new Swiper('[data-slider-gallery-modal]', swiper);
                this.sliderInstance.init();
            }
        });
    }
}

