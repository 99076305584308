import Component from "./Component";
import VenveoApplication from "./VenveoApplication";

type ComponentContainerConfig = {
    handle: string;
    $element: Element | null;
    component: Component | null;
    resolver: CallableFunction|null,
    deferred: boolean;
    lazy: boolean;
    options: object;
    $application: VenveoApplication;
}

export default class ComponentContainer {
    private config: ComponentContainerConfig;
    private instance: Component | null;

    constructor(config: ComponentContainerConfig) {
        this.config = config;
        this.instance = null;
    }

    async asyncSetup(): Promise<void> {
        const {default: Component} = await this.config.resolver();
        this.config.component = Component;
        this.setup();
    }

    setup(): void {
        if (this.config.component === null) {
            console.error('Could not initialize null component.')
            return;
        }
        this.instance = new this.config.component(this.config.options)
        console.debug('[APP] SETUP', this);
    }

    init(): void {
        if (this.instance) {
            this.instance.init();
            console.debug('[APP] INIT', this);
        } else {
            console.error('Init called before setup')
        }
    }

    ableToInitialize(): boolean {
        return !!this.config.$element
    }

    isDeferred(): boolean {
        return this.config.deferred
    }
}